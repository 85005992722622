<template>
  <div class="body-cont">
		<div class="div-container app-header">
			<div class="div-row">
				<div class="div-col12">
          <NavTopbar :user="user"/>
				</div>
			</div>
		</div>
    <div class="app-body-container appbody-content">
			<div class="app-body-row">
				<div class="app-body-col">
          <GmapMap :center="center" :zoom="7" map-type-id="terrain" style="height:90vh">
                    <GmapCluster>
                       <GmapMarker :key="index" v-for="(m, index) in markers" :position=m.position :clickable="true" :draggable="false" @click="infoView(m)" icon:m.icon/>
                    </GmapCluster>
                  </GmapMap>

                   <!-- The Modal -->
                   <div id="myModal" class="modal" v-show="showpopup">
                        <!-- Modal content -->
                        <div class="modal-content">
                          <div class="modal-header profile-modalheader">
                            <span class="prfmdlclose" @click="CloseModel()">&times;</span>
                          </div>
                          <div class="modal-body profile-modalbody">
                            <img :src="this.place_avatar_full_path" class="profl-img">
                            <div class="profl-contbox">
                              <p class="profl-name"><span></span>{{ this.place_heading }}</p>
                              <p class="profl-descrip"><span class="profl-descspan"></span>
                                {{ this.place_description }}
                              </p>
                              <p class="datetme-txt">{{ printdaytime(this.datetimes) }}</p>
                            </div>
                          </div>
                        </div>
                    </div>
        </div>
			</div>
		</div>
    <div class="app-bottom-container app-bottom">
			<div class="app-bottom-row">
				<div class="app-bottom-col">
					<table class="botbar-table">
						<tbody>
							<tr>
								<td>
									<div class="search-sec">
										<!-- <input type="text" name="" placeholder="Search"><span><i class="fa-solid fa-magnifying-glass"></i></span> -->
										<div class="input-group">
                            <input type="text" id="search" class="form-control input-form" placeholder="Search" @keypress="getUsersBykeyword" v-on:keyup.enter="getUsersBykeyword" v-on:change="getUsersBykeyword" @blur="getUsersBykeyword">
                            <div class="input-group-append">
                              <span class="input-group-text input-icon" id="" ><i class="fa-solid fa-magnifying-glass"></i></span>
                            </div>
                     </div>
									</div>
								</td>
								<td>
									<div class="filt-sec">
										<p class="filt-view"><button type="button" id="thumbvewbtn" @click="gotoDashbord()" class="thumbview-span"><i class="fa-solid fa-table-cells-large" ></i></button><button type="button" id="mapvewbtn"  class="mapview-span"><i class="fa-solid fa-earth-americas" style="color: blue;"></i></button></p>
									</div>
								</td>
							</tr>
						</tbody>
					</table>


				</div>
			</div>
		</div>
	</div>

</template>
<script>
//import Peer from "simple-peer";
import api from "@/api.js";
import _ from "lodash";
import BottomNav from "@/components/BottomNav.vue";
import NavTopbar from "@/components/NavTopbar";

import SearchBar from "@/components/SearchBar";
import { mapState } from "vuex";

export default {
  props: ["user"],
  components: {
    "search-bar": SearchBar,
    "BottomNav":BottomNav,
    "NavTopbar":NavTopbar,

  },

  data() {
    return {
      active:false,
      imageUrls: {
        mingle: {
          default: "/images/icon/mingle",
          active: "/images/icon/mingle_active.png"
        },
        place: {
          default: "/images/icon/place",
          active: "/images/icon/place_active.png"
        },
        product: {
          default: "/images/icon/product",
          active: "/images/icon/product_active.png"
        }
      },
      dialog: false,
      showingPlayback: false,

      map: null,
      infoWindow: null,
      videoStreamingUsers: [],
      peers: {},
      stream: null,
      isCameraEnabled: false,
      clickedUserId: null,
      isViewer: false,
      isStreamer: false,
      showMap: false,
      showVideo: false,
      showCategory: false,
      viewers: [],
      streamingUser: null,
      isLoading: {
        availableUsers: false,
        avatar: false,
        user: false
      },
      placeStreamingInfo: {
        place_heading: "",
        place_description: ""
      },
      showSnackbar: false,
      showStreamingUsersList: false,
      showSearchBar: false,
      isFrontCamera: true,
      otherUserId: null,
      currentIndex:0,
      isFavourite:false,
      pagination:[],
      isDateTime:false,
      room_id:'',
      isloadings:'f',
      iserror:false,
      popupUser:null,
      activeSlide:0,
      markers: JSON.parse(localStorage.getItem("marker")),
        center:JSON.parse(localStorage.getItem("center")),
        mapOptions: {
          disableDefaultUI: true,
        },
        showpopup:null,
        place_avatar_full_path:'',
        place_heading:'',
        place_description:'',
        datetimes:''
    };
  },
  computed: mapState("common", ["selectedBottomNavItem"]),
  computed:{
    items() {
            const items = [
                { name: "userMenu", text: this.user.name, icon: "fa-solid fa-user" },
                {
                    name: "notifications",
                    text: "Notifications",
                    icon: "fa-solid fa-bell"
                },
                { name: "contacts", text: "Contacts", icon: "fa-regular fa-address-book" },
               /* {
                    name: "people",
                    text: "People",
                    icon: "fa-solid fa-users"
                },*/
                /*{
                    name: "randomChat",
                    text: "Random Chat",
                    icon: "mdi-play-circle-outline"
                },*/
                //{ name: "settings", text: "Settings", icon: "fa-solid fa-gear" },
                { name: "logout", text: "Logout", icon: "fa-sharp fa-solid fa-right-from-bracket" }
            ];
            return items;
        },
    },
  beforeMount() {
    //this.checkisSteaming([]);

  },
  mounted() {
    //this.getVideoStreamingUsers();
    this.mapview();
    this.getUsers();
    setInterval(() => {this.getVideoStreamingUsers();}, 30000);

   // this.checkisSteaming();
  },
  watch: {

    user: {
      handler(value) {
        if (!value.id) {
          return;
        }
        //this.setupPusher();
      },
      deep: true
    },
    isViewer(value) {
      if (!value) {
        this.updateViewerStatus(0);
      }
    },
    isStreamer(value) {
      if (!value) {
        this.finishStreaming();
      }
    },
    $route(route) {
      //this.getUsersBykeyword(route.query);
    },
    selectedBottomNavItem(value) {
      if (value.name === "search") {
        this.showSearchBar = !this.showSearchBar;
      } else {
        this.showSearchBar = false;
        if (value.name === "filters") {
          // for now disable filter item on the bottom as there is one on the top.
          // this.openFilter = true;
        }
      }
    }
  },
  methods: {
    gegeotLocation() {
        navigator.geolocation.getCurrentPosition(this.callback, this.getError,{enableHighAccuracy: true, maximumAge: 10000});
    },
    callback (position) {
           const  currentlat = position.coords.latitude;
           const currentlng = position.coords.longitude;
           const Thecenter={center: {lat: currentlat, lng: currentlng }};
            console.log("latitude: " + currentlat+"\nlongitude: "+ currentlng);
            localStorage.setItem("center", JSON.stringify(Thecenter.center));

           // alert("latitude: " + currentlat+"\nlongitude: "+ currentlng);

    },
    getError(err){
           // console.log(err);
            alert(err.message);
    },
    infoView(data){
      console.log(data);
      if(data.streamingUrl!=''){
        location.href =data.streamingUrl;
        return;
      }
      localStorage.setItem("showpopup", true);
      this.showpopup=true;
      this.place_avatar_full_path=data.place_avatar_full_path;
      this.place_heading=data.place_heading;
      this.place_description=data.place_description;
      this.datetimes=data.datetimes;
    },
     async mapview(){
        try {
            const userauth = localStorage.getItem("userId");
            var result= await api.user.mapView(userauth);
            this.markers=result.data.marker;
           // this.center=result.data.center;
           localStorage.setItem("center", '');
            this.gegeotLocation();
            localStorage.setItem("marker", JSON.stringify(result.data.marker));
           // localStorage.setItem("center", JSON.stringify(result.data.center));
            localStorage.setItem("center", JSON.stringify(this.center));

          } catch(error) {
              console.log(error);
          }
          this.center=JSON.parse(localStorage.getItem("center"));
          this.markers=JSON.parse(localStorage.getItem("marker"));
      },

    async toggleNotifications() {
            this.showAddressBook = false;
            this.showNotifications = true;
            this.active=false;
            await api.notification.markAsRead();
           // this.getUser();
    },

    async getUsers() {
            let res = await api.user.getAll();
            this.pagination = res.data.data;
            //console.log(this.pagination.data);

    },

    async checkisSteaming(streamingUser){
      if(streamingUser.roomid==''){
        this.$alert("Streaming is offline");
        return;
      }else{
        const res = await api.user.getRoomID(Number(streamingUser.id));
        this.room_id=res.data;
        this.isloadings=res.data;
        this.room_id=res.data;
        var room_id=res.data;
      }

       let options={
          host: "nodejs.safepestadmin.com.au",
          path: '/',
          debug:1,
          config: {
              'iceServers': [
                  { url: 'stun:stun1.l.google.com:19302' },
                  {
                      url: 'turn:numb.viagenie.ca',
                      credential: 'muazkh',
                      username: 'webrtc@live.com'
                  }
              ]
          }
        };

        let peer = new Peer(options);

        peer.on('open', (id) => {
          navigator.mediaDevices.getUserMedia({ video:true, audio: false }).then(stream => {
          let call = peer.call(room_id, stream);

          this.isloadings='f';
          console.log(call);
                  call.on('stream', (Remotestream) => {
                    console.log('ok');
                    this.$refs.liveto.srcObject=Remotestream;

                    this.clickVideoStreamingHandler(streamingUser);
                  });

                  const videoTracks=stream.getVideoTracks();
                        for (let i = 0; i !== videoTracks.length; ++i) {
                              videoTracks[i].stop();
                  }
          });
        });

      peer.on('error', (err)=>{
        this.iserror=true;
                  if(err.type == 'peer-unavailable'){
                    //alert('Streamer is Offline');
                  }
                  this.isloadings='f';
                  this.$alert("Streaming is offline");
                 // this.$alert("Streaming is offline");
                  console.log(err.type);
                  //return;
                });


    },

    CloseNotification(){
      this.showNotifications= false;
      this.showAddressBook = false;
      this.active=true;
    },
    toggleAddressBooksclose(){
      this.showAddressBook= false;
      this.showNotifications = false;
      this.active=true;
    },

    async toggleAddressBooks() {
            this.showNotifications = false;
            this.showAddressBook = true;
            this.active= false;
    },
    menuActionHandler(item) {
            switch (item.name) {
                case "userMenu":
                    location.href = `/user/${this.user.id}`;
                    break;
                case "notifications":
                    this.toggleNotifications();
                    break;
                case "contacts":
                    this.toggleAddressBooks();
                    break;
                //case "people":
                //    this.goToHome();
                //    break;
               /* case "randomChat":
                    location.href = "/video-chat";
                    break;
                case "settings":
                    location.href = "/settings";
                    break;
                case "liveVideoId":
                    this.openLiveVideoIdDialog();
                    break;
                    */
                case "logout":
                    this.logout();
                    break;
            }
    },
    mouseOverFun(){
            if(this.active){
            this.active =false;
            }else{
              this.active=true;
            }
    },
    mouseOverout: function(){
            this.active =false;
    },
    gotoPeople(){
      location.href = "/?page=1";

    },
    gotoDashbord(){
      location.href = "/dashboard";

    },
    touchStartMethod (touchEvent) {
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXStart = touchEvent.changedTouches[0].clientX;
        addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
    },
    touchEndMethod (touchEvent, posXStart) {
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXEnd = touchEvent.changedTouches[0].clientX;
        if (posXStart < posXEnd) {
          this.previous(); // swipe right
        } else if (posXStart > posXEnd) {
          this.next(); // swipe left
        }
    },

    next(){
      this.back = false;
      this.currentIndex++;
    },
    previous(){
      this.back = true;
      this.currentIndex--;
    },


    goToHome() {
      location.href = "/";
        },

    goToplace(){
        location.href = "/places";

    },

    logoSrc() {
            return "/images/logo/net-app-log-white.png";
    },
    logoVideoSrc() {
            return "/images/logo/video-camera-white.png";
    },
    goToNewStreamingPage() {
            location.href = "/new-streaming";
    },
    updateUriByKeyword(keyword = "") {
      const query = { heading: keyword };
      this.$router.push({
        query
      });
    },
    async getUsersBykeyword(e) {

      this.isLoading.user = true;
      console.log(this.activeSlide);
      if(this.activeSlide==1){
      const res = await api.user.getVideoStreamingUsers({
        params: {
          category: "place",
          heading: e.target.value
        }
      });
      this.videoStreamingUsers = res.data;
      this.isLoading.user = false;
    }else if(this.activeSlide==0 || this.activeSlide==''){
            let res = await api.user.search({

              heading: e.target.value
            });
            this.pagination = res.data;

    }
    },
    routeTo(route) {
      location.href = `/${route}`;
    },
    changeImage(imgCategory) {
      this.imageUrls[
        imgCategory
      ].default = `${this.imageUrls[imgCategory].default}_active`;
    },
    returnImage(imgCategory) {
      this.imageUrls[imgCategory].default = this.imageUrls[
        imgCategory
      ].default.replace("_active", "");
    },

    showPlayback() {
      console.log("show play back");
    },
    async addFavourite(user) {
      //user.isFavourite = !user.isFavourite;
      let isFavourite=0;
      if(user.isFavourite==0){
        isFavourite=1;
      }else{
        isFavourite=0;
      }
      this.isLoading.user = true;
      const res = await api.user.updateFavourite(user.id,
        {
          isFavourite: isFavourite,
          id:user.id
        }
      );
      this.getUsers();
      //window.location.reload(); // Reloads the current page
      this.isLoading.user = false;
      console.log("add favourite");
    },
    // clickVideoStreamingHandler(user) {
    //     this.showStreamingUsersList = false;
    //     this.streamingUser = user;
    //     this.joinVideoStream(user.id);
    // },
    clickVideoStreamingHandler(user) {
      location.href = `/live-streaming/${user.id}`;
    },
    finishVideoChat() {
      const peer = this.peers[this.otherUserId];
      if (peer !== undefined) {
        peer.destroy();
      }

      delete this.peers[this.otherUserId];
      this.otherUserId = null;
      this.otherUser = null;
      // this.updateVideoChatAvailability(this.user.id, 0);
    },
    getPermissions() {
      let constraints = {
        audio: true,
        video: {
          facingMode: this.isFrontCamera ? "user" : "environment"
        }
      };
      return new Promise(res => {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isCameraEnabled = true;
            res(stream);
          })
          .catch(err => {
            this.isCameraEnabled = false;
            throw new Error(`Unable to fetch stream ${err}`);
          });
      });
    },
    async updatePlaceStreamingInfo() {
      const obj = {
        place_heading: this.user.place_heading,
        place_description: this.user.place_description,
        place_avatar_full_path: this.user.place_avatar_full_path
      };
      await api.user.update(this.user.id, obj);
      this.showSnackbar = true;
    },
    async updateViewerStatus(status) {
      if (status) {
        await api.user.addViewer(this.streamingUser.id, this.user.id);
      } else {
        await api.user.removeViewer(this.streamingUser.id, this.user.id);
      }
    },
    async finishStreaming() {
      await api.user.updateVideoStreamingDetails(this.user.id, {
        is_streaming: false
      });
    },
    async getAllViewers() {
      const res = await api.user.getAllViewers(this.user.id, {
        params: {
          category: "place"
        }
      });
      this.viewers = res.data;
    },
    async getVideoStreamingUsers() {
      const res = await api.user.getVideoStreamingUsers({
        params: {
          category: "place"
        }
      });
      this.videoStreamingUsers = res.data;
    },

    async logout(userId = this.user.id) {
            this.showChatbox = false;
            try {
                await api.user.updateToOffline(userId);
            } finally {
                if (this.user.id) {
                    await api.auth.logout();
                }
                 localStorage.removeItem("userId");
                 localStorage.removeItem("isLoggedIn");
                 location.href = "/login";
                 this.$router.push({ name: "Login" });
            }
        },

    resetUserType() {
      this.isStreamer = false;
      this.isViewer = false;
      this.showMap = false;
      this.showStreamingUsersList = false;
    },
    startStreaming() {
      this.getPermissions().then(stream => {
        this.stream = stream;
      });
      this.resetUserType();
      this.isStreamer = true;
      setInterval(() => {
        this.getAllViewers();
      }, 5000);
      this.getCurrentPosition();
      setTimeout(() => {
        const streamerVideo = this.$refs["streamer-video"];
        this.getPermissions().then(stream => {
          try {
            streamerVideo.srcObject = stream;
          } catch (e) {
            streamerVideo.src = URL.createObjectURL(stream);
          }
          streamerVideo.srcObject = stream;
          streamerVideo.play();
        });
      }, 500);
    },

    async playRandomStreaming() {
      const res = await api.user.placeVideoStreamingAvailable();
      const user = res.data;
      this.resetUserType();
      this.joinVideoStream(user.id);
    },

    setupPusher() {

    },

    async getCurrentPosition() {
      if (navigator.geolocation) {
        await api.user.update(this.user.id, {
          is_streaming: true
        });
        navigator.geolocation.getCurrentPosition(
          async position => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              is_streaming: true
            };
            await api.user.updateVideoStreamingDetails(this.user.id, pos);
            this.infoWindow.setPosition(pos);
            this.infoWindow.setContent("Location found.");
            this.infoWindow.open(this.map);
            this.map.setCenter(pos);
          },
          () => {
            this.handleLocationError();
          }
        );
      } else {
        // Browser doesn't support Geolocation
        this.handleLocationError();
      }
    },

    handleLocationError() {
      console.log("error ");
    },

    searchButtonClickHandler() {
      this.resetUserType();
      this.showStreamingUsersList = true;
      this.getVideoStreamingUsers();
    },




    setVideoAttributeForIOS(video) {
      video.setAttribute("autoplay", "");
      video.setAttribute("muted", "");
      video.setAttribute("playsinline", "");
    },
    imageClickHandler() {
      this.$refs["file-uploader"].click();
    },
    onFileChange(e) {
      this.$set(this.user, "place_avatar_full_path", "");
      let files = e.target.files || e.dataTransfer.files;
      this.passedData = files[0].name;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      this.isLoading.upload = true;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        let src = e.target.result;
        this.onLoad({
          src,
          file
        });
      };
    },
    async onLoad(data) {
      try {
        // this.$refs.errors.error = "";
        let formData = new FormData();
        formData.append("avatar", data.file);
        this.user.place_avatar_full_path = "";
        const res = await api.user.uploadPlaceAvatar(this.user.id, formData);
        this.user.place_avatar_full_path = res.data.place_avatar_full_path;
        this.isLoading.upload = false;
      } catch (err) {
        console.log(err.message);
      }
    },
    switchCamera() {
      this.isFrontCamera = !this.isFrontCamera;
      if (typeof this.stream !== "undefined") {
        this.stopMediaTracks(this.stream);
      }
      const videoConstraints = {
        facingMode: {}
      };
      const streamerVideo = this.$refs["streamer-video"];
      videoConstraints.facingMode = this.isFrontCamera
        ? "user"
        : { exact: "environment" };
      const constraints = {
        video: videoConstraints,
        audio: true
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.stream = stream;
          streamerVideo.srcObject = stream;
          streamerVideo.onloadedmetadata = e => {
            streamerVideo.play();
          };
        })
        .catch(error => {
          console.error(error);
        });
    },
    stopMediaTracks(stream) {
      stream.getTracks().forEach(track => {
        track.stop();
      });
    },
    showCategoryPage() {
      this.showMap = false;
      this.showCategory = true;
    },
    mouseOverActionHandler(user, event) {
      //this.$set(user, "showing_playback", true);
    },
    mouseLeaveActionHandler(user) {
      this.$set(user, "showing_playback", false);
    },
    addToFavourite(user) {
      console.log("add to favourite ", user);
    },
    showdatetime(datetime){
      if(datetime=="0000-00-00 00:00:00"){
        this.isDateTime='';
      }
      else if(this.isDateTime==datetime){
        this.isDateTime=null;
      }else{
        this.isDateTime=datetime;
      }
      //this.isDateTime=!this.isDateTime;
    },
    OpenModel(username){
        this.popupUser=username;
    },
    CloseModel(){
        this.showpopup=null;
      //this.isDateTime=!this.isDateTime;
    },


    printdaytime(datetime){
      if(datetime=="0000-00-00 00:00:00"){
        return'';
      }
      // Get the current date and time
      var today = new Date(datetime);
      // Get the day of the week (0-6, where 0 is Sunday and 6 is Saturday)
      var day = today.getDay();
      // Array of day names
      var daylist = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      // Display the current day
      //console.log("Today is: " + daylist[day] + ".");

      // Get the current hour, minute, and second
      var hour = today.getHours();
      var minute = today.getMinutes();
      var second = today.getSeconds();

      // Determine if it's AM or PM
      var prepand = (hour >= 12) ? " PM " : " AM ";

      // Convert 24-hour format to 12-hour format
      hour = (hour >= 12) ? hour - 12 : hour;

      // Check for special cases when hour is 0
      if (hour === 0 && prepand === ' PM ') {
          if (minute === 0 && second === 0) {
              hour = 12;
              prepand = ' Noon';
          } else {
              hour = 12;
              prepand = ' PM';
          }
      }

      // Check for special cases when hour is 0
      if (hour === 0 && prepand === ' AM ') {
          if (minute === 0 && second === 0) {
              hour = 12;
              prepand = ' Midnight';
          } else {
              hour = 12;
              prepand = ' AM';
          }
      }
      // Display the current time
      //return ''+ daylist[day]+ ',' + hour + prepand + " : " + minute;
      return ' '+ today.getDate() +' '+ month[today.getMonth()]+ ', ' + hour + prepand;
      },
    getreviewimage(value){
            console.log(value);
            if(value==50){
                return '/images/icon/sheild_icon_50.png';
            }else if(value>50 && value<60){
                return '/images/icon/sheild_icon_60.png';
            }
            else if(value==60){
              return '/images/icon/sheild_icon_60.png';
            }
            else if(value>60 && value<70){
              return '/images/icon/sheild_icon_70.png';
            }
            else if(value==70){
                return '/images/icon/sheild_icon_70.png';
            }
            else if(value>70 && value<80){
                return '/images/icon/sheild_icon_80.png';
            }
            else if(value==80 ){
                return '/images/icon/sheild_icon_80.png';
            }
            else if(value>80 && value<90){
                return '/images/icon/sheild_icon_90.png';
            }
            else if(value==90){
                return '/images/icon/sheild_icon_90.png';
            }
            else if(value>90 && value<95){
                return '/images/icon/sheild_icon_100.png';
            }
            else if(value==100){
                return '/images/icon/sheild_icon_100.png';
            }
            else{
                return '';
            }
        }
  }
};
</script>
<style>
        * {box-sizing: border-box;
          padding: 0;
        margin: 0;
      }


body{
    font-family: "Open Sans", sans-serif;
}


    .div-container.app-header {
        background: #2e2e2e;
        height: auto;
        padding: 5px;
        box-shadow: 0px 6px 8px -5px rgba(0,0,164,0.29);
      }
      .div-col12 {
  width: 100%;
}
.app-body-container.appbody-content {
  padding: 15px 0;
}
.div-row {
  flex-wrap: wrap;
  display: flex;
}
      .body-cont {
  max-width: 700px;
  margin: 0 auto;
}
.logo-sec {
  width: 60px;
  float: left;
}
.app-logo {
  width: 100%;
}

ul{
  padding: 0;
  margin: 0;

}
.headlogo-li{
  list-style: none;
    float: left;
    width: 33.3%;
}
.video-icon-sec {
  width: 50px;
margin: 5px auto;
}
.profile-img-sec {
  width: 60px;
  border-radius: 100%;
  float: right;
  text-align: center;
  height: 60px;
  padding-top: 6px;
}
.net-profile-img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 50px;
  box-shadow: 0px 6px 12px 0px rgba(0,0,164,0.29);
  z-index: 1;
  top: 70px;
  right: 0;
  text-align: center;
}

.dropdown-content a {
  color: #3C4043;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  text-align: center;
}

.dropdown-content a:hover {background-color: #ddd; color:#0000A4;}

.dropdown:hover .dropdown-content {display: block;}

.profile-menu i{
  font-size: 16px;

}
.app-profl-sec{
    position: relative;
  background: #BBB6B6;
  border-radius: 20px;
  box-shadow: 0px 0px 14px -6px rgba(0,0,164,1);
}


.app-body-contli {
width: 44%;
list-style: none;
margin: 10px;
display: inline-block;
}
.profile-cont-img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  min-height: 200px;
  border-radius: 20px;

}
.text-block {
  position: absolute;
  bottom: 0;
  background: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.9);
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity: 1;
  color: #3C4043;
  font-size: 20px;
  padding: 10px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.profname {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}
.app-body-ul{
  margin: 0 auto;
}
.profname span{
  width:33.3%;
  float: left;
  font-size: 16px;
  padding: 0;
margin: 0;
}
.profclock-span {
  text-align: center;
    padding: 0;
margin: 0;
}
.profstar-span {
  text-align: right;
  padding: 0;
margin: 0;
}
.profstar-span:hover {
  color: #E4AC1A;
}
/*.search-sec {

  border-radius: 100px;
  border: 1px solid #A6A6A6;

  padding: 5px;
}*/
.search-sec input{
  border: none;
  width: 75%;
  border-radius: 100px;
  padding: 10px 15px;
}
.app-bottom-col {
  display: flex;
}

.filt-view {
  text-align: right;
  text-align: center;
}
.thumbview-span {
  width: 50%;
display: inline-block;
text-align: center;
padding: 0 8px;
border-top-left-radius: 30px;
border-bottom-left-radius: 30px;
}
.mapview-span{
  width: 50%;
display: inline-block;
text-align: center;
padding: 10px 8px;
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;


}
.filt-view {
  padding: 0;
  margin: 0;
border: 1px solid #ced4da;
border-radius: 30px;

}
.filt-view button{
  background: #fff;
  border: none;
}
.app-bottom-container.app-bottom {
  background: #fff;
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.appbody-profcont{
  width: 100%;
}
.appbody-profcont tr td{
  padding: 10px;
}
.prof-namcont tr td{
  padding: 10px 5px;
  width: 33%;
}
.prof-namcont{
  width: 100%;
}
.botbar-table{
  width: 100%;
}
.botbar-table tr td:nth-child(1){
  width: 65%;
}


.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-control.input-form {
  padding: 15px 15px;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  margin: 0;
    margin-bottom: 0px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-icon {
  background: transparent;
  color: #888;
  font-size: 24px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.app-bottom-row {
  width: 100%;
}

table {
  border-collapse: collapse;
}
.form-control:focus-visible{
  border: none;
  outline: none;
  box-shadow: 0;
}

.search-sec .input-group {
  border: 1px solid #ced4da;
  border-radius: 50px;
}
.input-group-text.input-icon {
  border: none;
  background: transparent;
}



.mainprof-sec .input-group {
  border: 2px solid #ced4da;


}


.cover-photosec {
  display: block;
  width: 100%;
  height: 300px;
  border: 2px solid #ced4da;

  margin-top: 15px;

}
.mainprof-botbar-table {
  width: 100%;
}
.bot-livbtn {
width: 60px;
display: block;
border: none;
border-radius: 100%;
height: 60px;

margin: 0 auto;
text-align: center;
box-shadow: 0px 0px 16px -8px rgba(0,0,164,0.75);
color: #949497;
font-weight: 500;
}
.bot-livbtn .strambt-icon {
  width: 100%;
}
.mainprof-botbar-table tr td {
  width: 20%;
  text-align: center;
}
.bot-icon i{
  font-size: 18px;
  color: #949497;
}
.bot-icon {
  display: block;
  width: 45px;
  margin: 0 auto;
  height: 45px;
  border-radius: 100%;
  line-height: 30px;
  background: #DFDFDF;
}
.bot-icon img{
  width: 100%;
}
.bot-icon.active{
  background: #0000A4;

}
.bot-icon.active i{
  color: #fff !important;
}

.mainprof-sec .input-group {
  border: 2px solid #ced4da;
}
.form-control.input-form {
  border: none;
}
.mart15{
  margin-top: 15px;
}
.app-body-container.cratstrbody-content{
  padding: 15px;
}
.input-group.mainprof-input {
  border: 2px solid #ced4da;
}



.app-body-container.livebody-content {
  height: 90vh;
}
.live-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  height: 90vh;
}
.livestream-body{
  height: 90vh;
  position: relative;
  z-index: 0;
}
.goliv-txt {
  font-size: 16px;
  padding: 20px 0 10px;
  color: #000;
}





/*Setting page style Start*/
.setingcont-table{
  width: 100%;
}
.setingcont-table tr td{
  text-align: center;
  width: 50%;
  padding: 0;
}
.setting-tab {
  border: 2px solid #1E1EA0;
  border-radius: 50px;
  box-shadow: 0px -5px 11px -8px rgba(0,0,164,0.75);
}
.setingcont-table tr td a{
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: #1E1EA0;
}
.tab-heading-active{
  background: #1E1EA0;
  color: #fff !important;

}
.priterm-cont {
  border: 2px solid #000;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  max-height: 63vh;
  margin-bottom:30px;
}
.setting-pgheading {
  padding: 0;
  margin: 0;
  font-size: 20px;
  margin-bottom: 15px;
}
.priterm-txt {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.privacy-tab{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.terms-tab{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.app-body-setingcont {
  padding: 10px;
}

/*Setting page style Start*/




/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 25px 10px;
  width: 95%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.prfmdlclose {
  color: #000;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}

.prfmdlclose:hover,
.prfmdlclose:focus {
  color: #000;
 /* text-decoration: none; */
  cursor: pointer;
}



.modal-body.profile-modalbody {
  text-align: center;
}
.modal-body.profile-modalbody .profl-img {
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid #000;
}

.profl-contbox {
  padding: 15px;
  text-align: left;
}

.modal-body.profile-modalbody .profl-name {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
}
.modal-body.profile-modalbody .profl-descrip {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10px;
}
.modal-body.profile-modalbody .datetme-txt {
  font-size: 16px;
  font-weight: 600;
}





button{
  border: none;
  outline: none;
}
.postliv-table button{
  background: transparent;
}
.postliv-table button img{
  width: 60px !important;
  margin-top: 4px;
  max-height: 40px !important;
  object-fit: cover;
}
.postliv-table {
  width: 130px;
  margin: 0 auto;
  padding: 5px !important;
  background: #1E1EA0;
  border-radius: 40px;
  box-shadow: 0 0 16px -8px rgba(0,0,164,.75);
}
.postliv-table .strm-btn.post-btn img{
  border-right: 1px solid #fff;
}
/*.postliv-table .strm-btn.live-btn img{
  border-left: 2px solid #fff;
}*/




.conttxt{
  font-size: 14px;
  color: #000;
  padding: 7px;
}
.conttxt-heading {
  font-size: 16px;
  padding: 5px 0;
}
.conttxt-ul {
  margin-left: 30px;
}
.conttxt-mainheading {
  font-size: 18px;
  padding: 10px 0;
}

.conttxt-ul li{
  font-size: 14px;
  color: #000;
}

.appmenu-title {
  font-size: 16px;
  padding: 0 10px 10px 10px;
}
.profnamtxt {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}
.profnamicon i{
   font-size: 18px;
  text-align: right;
}
.profnamicon {
  text-align: right;
}
.text-block .posttxt {
  font-size: 12px;
  padding-top: 5px;
}
.livtxt{
    text-align:left;
}

.profl-descspan {
  font-weight: 700;
}
.app-body-col{
  overflow: hidden;
}

.modal{
  display: block;
}

.dropdown:hover .dropdown-content {
    z-index: 11111 !important;
}

.yellowColor{
  color:yellow
}













</style>

